import React from 'react'
import Layout from '../components/layout/Layout'
import withLocation from '../components/generic/withLocation'
import { PageInformation, ParsedSku } from '../types'
import Helmet from 'react-helmet'
import { trackEvent } from '../utils/ArtboxoneTracking'
import AlternativesOverview from '../components/products/AlternativesOverview'
import { useTranslation } from 'react-i18next'

export interface AlternativesPageOptions {
  materialSlug: string
  sku: string
  location: any
}

const parseSku = (sku: string) => {
  const result: ParsedSku = {
    motiveId: 0,
    slug: '',
    materialIId: 0,
  }

  if (typeof sku != `undefined`) {
    const matches = sku.match(/([0-9]+)-(.+)/)

    if (matches != null && typeof matches[2] != 'undefined') {
      result.slug = matches[2]
      result.motiveId = parseInt(matches[1])
    }
  }

  return result
}

const AlternativesPage = (props: AlternativesPageOptions) => {
  const material = props.materialSlug
  const sku = parseSku(props.sku)

  trackEvent({
    category: 'pes',
    action: 'alternative',
    label: '' + props.sku,
  })

  const pageInformation: PageInformation = { type: 'pes', material: material }
  const robots = 'noindex, follow'
  const { t } = useTranslation('translation')

  return (
    <>
      <Helmet>
        <meta content={robots} name="robots" />
      </Helmet>
      <Layout pageInformation={pageInformation}>
        <h1>{t('Deine Wunschmotive')}</h1>
        <p>
          {t(
            'Wir können dein aufgerufenes Motiv gerade nicht finden. Hier hast du eine Auswahl an vergleichbaren Designs.',
          )}
        </p>

        <AlternativesOverview
          key={'alternatives-' + material + '-' + sku.motiveId}
          label={'alternatives'}
          material={material}
          motiveId={sku.motiveId}
        />
      </Layout>
    </>
  )
}

export default withLocation(AlternativesPage)
